.link {
    position: relative;
    transition: all ease .3s;
    &:hover {
        cursor: pointer; }
    &::after {
        content: '';
        height: 3px;
        width: 0;
        background: #fff;
        position: absolute;
        bottom: -10px;
        left: 0; }
    &:hover::after {
        animation: lineElement .3s linear forwards; } }

@keyframes lineElement {
    0% {
        width: 0%; }
    100% {
        width: 100%; } }

